<template>
	<div>
		<el-table :data="details">
			<el-table-column label="Doanh số key">
				<template slot-scope="scope">
					{{ scope.row.doanh_so_key ??  scope.row.doanh_so_doi}}
				</template>
			</el-table-column>

			<el-table-column label="Doanh số Digital">
				<template slot-scope="scope">
					{{ scope.row.doanh_so_digital }}
				</template>
			</el-table-column>
		</el-table>
	</div>
</template>

<script>

export default {
	name: "ProvinceDetail",
	props: {
		details: {
			type: Array,
			default: [],
		}
	}
}
</script>

<style scoped>

</style>