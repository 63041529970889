export const data = [
  {
    key: 'muc_luong_thoi_gian',
    title: 'Mức lương thời gian',
    prefix: 'currency',
  },
  {
    key: 'muc_luong_hoan_thanh_cv',
    title: 'Mức lương Hoàn thành CV',
    prefix: 'currency',
  },
  {
    key: 'so_hop_dong_nhan',
    title: 'Số hợp đồng nhân',
  },
  {
    key: 'doanh_so_nhan',
    title: 'Doanh số nhân',
  },
  {
    key: 'attendance',
    title: 'Ngày công tháng',
  },
  {
    key: 'kpi_deadline',
    title: '% KPI hoàn thành công việc',
    prefix: '%',
  },

  {
    key: 'tong_thu_nhap',
    title: 'Tổng thu nhập',
    prefix: 'currency',
  },
  {
    key: 'tien_phu_cap',
    title: 'Phụ cấp',
    prefix: 'currency',
  },
  {
    key: 'thuong',
    title: 'Thưởng',
    prefix: 'currency',
  },
  {
    key: 'thu_nhap_thieu_thang_truoc',
    title: 'Thu nhập thiếu tháng trước',
    prefix: 'currency',
  },
  {
    key: 'phat',
    title: 'Phạt',
    prefix: 'currency',
  },
  {
    key: 'tru_ao_qlhv',
    title: 'Trừ áo QLHV',
    prefix: 'currency',
  },
  {
    key: 'tru_khac',
    title: 'Trừ khác',
    prefix: 'currency',
  },
  {
    key: 'salary',
    title: 'Lương thực nhận',
    prefix: 'currency',
  },
];