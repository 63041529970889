<template>
  <div class="row">
    <div class="col-md-12 col-xl-12">
      <el-row :gutter="20" class="mb-g search-thong-bao">
        <el-col :xs="24" :sm="16" :md="8" :lg="6" :xl="4" class="mt-2">
          <el-date-picker
              class="w-100"
              v-model="date"
              type="month"
              placeholder="Chọn tháng"
          >
          </el-date-picker>
        </el-col>
        <el-col :xs="24" :sm="8" :md="4" :lg="3" :xl="2" class="mt-2">
          <el-button
              class="w-100"
              type="primary"
              @click="searchLuong"
              icon="el-icon-search"
          >Tìm kiếm
          </el-button
          >
        </el-col>
      </el-row>
      <div class="row">
        <div class="col-lg-12 col-xl-3 order-lg-1 order-xl-1">
          <!-- profile summary -->
          <div class="card mb-g rounded-top">
            <div class="d-flex flex-row pb-3 pt-4 text-center  border-top-0 border-left-0 border-right-0">
              <h4 class="mb-0 flex-1 text-dark fw-500">Thông tin cơ bản phiếu lương tháng {{
                  date ?
                      (date.getMonth() + 1) + '/' + date.getFullYear() : ((new Date().getMonth()) + '/' + new
                  Date().getFullYear())
                }}</h4>
            </div>
            <div class="row no-gutters row-grid">
              <div class="col-12 p-4" v-if="data && data.is_v2">
                <h5 class="col-xs-6 d-flex justify-content-between">
                  <span class="d-inline-block">Họ và tên: </span>
                  <span v-if="data.ho_va_ten" class="fw-700">{{ data.ho_va_ten ? data.ho_va_ten : '' }}</span>
                  <span v-if="data.ho_ten" class="fw-700">{{ data.ho_ten ? data.ho_ten : '' }}</span>
                </h5>
                <h5 class="col-xs-6 d-flex justify-content-between">
                  <span class="d-inline-block">Mã nhân sự:</span><span
                    class="fw-700">{{ data.ma_nhan_su ? data.ma_nhan_su : '' }}</span>
                </h5>
                <h5 v-if="data.vi_tri" class="col-xs-6 d-flex justify-content-between">
                  <span class="d-inline-block">Vị trí:</span>
                  <span class="fw-700">{{ data && data.vi_tri ? data.vi_tri : '' }}</span>
                </h5>
                <h5 v-if="data.chuc_vu" class=" col-xs-6 d-flex justify-content-between">
                  <span class="d-inline-block">Chức vụ:</span>
                  <span class="fw-700">{{ data && data.chuc_vu ? data.chuc_vu : '' }}</span>
                </h5>
                <h5 class=" col-xs-6 d-flex justify-content-between" v-if="data && data.cap_bac">
                  <span class="d-inline-block">Cấp bậc:</span>
                  <span class="fw-700">{{ data.cap_bac }}</span>
                </h5>
                <h5 class=" col-xs-6 d-flex justify-content-between" v-if="data && data.khu_vuc">
                  <span class="d-inline-block">Khu vực:</span><span class="fw-700">{{ data.khu_vuc }}</span>
                </h5>
                <h5 class=" col-xs-6 d-flex justify-content-between" v-if="data && data.van_phong">
                  <span class="d-inline-block">Văn Phòng: </span><span class="fw-700">{{
                    data.van_phong
                  }}</span>
                </h5>
                <h5 v-if="['v2_vp_tong'].includes(data.account_type) && data.level_chuyen_mon"
                    class=" d-flex justify-content-between">
                  <span class="d-inline-block">Level chuyên môn: </span><span
                    class="fw-700">{{
                    data.level_chuyen_mon
                  }}</span>
                </h5>
                <h5 v-if="['v2_vp_tong'].includes(data.account_type) && data.level_quan_tri"
                    class=" col-xs-6 d-flex justify-content-between">
                  <span class="d-inline-block">Level quản trị: </span><span class="fw-700">{{
                    data.level_quan_tri
                  }}</span>
                </h5>
              </div>
              <div class="col-12 p-4" v-else>
                <h5 class="col-xs-6">
                  <span class="d-inline-block width-size">Họ và tên: </span><span
                    class="fw-700">{{ currentUser.name || (currentUser.user && currentUser.user.name) }}</span>
                </h5>
                <h5 class=" col-xs-6">
                  <span class="d-inline-block width-size">Mã nhân sự:</span><span
                    class="fw-700">{{ (currentUser.id || currentUser.user.id) + 100000 }}</span>
                </h5>
                <h5 class=" col-xs-6">
                  <span class="d-inline-block width-size">Vị trí:</span>
                  <span class="fw-700">{{ data ? data.account_type : currentUser.account_type.name }}</span>
                </h5>
                <h5 class=" col-xs-6" v-if="data && data.khu_vuc">
                  <span class="d-inline-block width-size">Khu vực:</span><span class="fw-700">{{ data.khu_vuc }}</span>
                </h5>
                <h5 class=" col-xs-6" v-else>
                  <span class="d-inline-block width-size">Văn Phòng: </span><span class="fw-700">{{
                    currentUser.branch
                  }}</span>
                </h5>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-12 col-xl-9 order-lg-3 order-xl-2">
          <div class="panel">
            <div class="panel-hdr">
              <h2>
                Phiếu lương nhân viên tháng {{
                  date ? (date.getMonth() + 1) + '/' + date.getFullYear() :
                      ((new Date().getMonth()) + '/' + new Date().getFullYear())
                }}
              </h2>
            </div>
            <div class="panel-container show">
              <div class="panel-content">
                <div class="row">
                  <div class="col-lg-12 col-md-12">
                    <el-row v-loading="loading"
                            v-if="data.id"
                    >
                      <table class="table table-bordered table-hover table-striped w-100 dataTable dtr-inline"
                             v-if="!data.is_v2">
                        <template v-for="(item, k) in listRow">
                          <phieu-luong-item
                              :key="item.title+'space'"
                              v-if="item.space_before_row"
                              :space_before_row="item.space_before_row">
                          </phieu-luong-item>
                          <phieu-luong-item
                              :class="k % 2 ? 'odd': 'even'"
                              v-if="!item.logsArray"
                              :key="item.title"
                              :title="item.title"
                              :value="getValue(item)"
                              :to="item.extraTime && rangeMonth"
                              :tooltip="item.tooltip || ''"
                              :title_class_name="item.title_class_name"
                          ></phieu-luong-item>
                          <template v-else>
                            <phieu-luong-item
                                :class="k % 2 ? 'odd': 'even'"
                                v-for="(log, index) in data.log_human_resources"
                                :key="index + item.title"
                                :hideTitle="index !== 0"
                                :rowSpan="data.log_human_resources.length"
                                :title="item.title"
                                :value="getValue(item, log)"
                                :to="log.giaidoan || rangeMonth"
                                :tooltip="item.tooltip || ''"
                                :title_class_name="item.title_class_name"
                            ></phieu-luong-item>
                          </template>
                        </template>
                      </table>
                      <table class="table table-bordered table-hover table-striped w-100 dataTable dtr-inline" v-else>
                        <thead>
                        <tr>
                          <th>Danh mục</th>
                          <th>Số liệu</th>
                          <th>Chi tiết</th>
                        </tr>
                        </thead>
                        <template v-for="(item, k) in listRow">
                          <phieu-luong-item
                              :key="item.title+'space'"
                              v-if="item.space_before_row"
                              :space_before_row="item.space_before_row">
                          </phieu-luong-item>
                          <phieu-luong-item
                              :class="k % 2 ? 'odd': 'even'"
                              v-if="!item.logsArray"
                              :key="item.title"
                              :title="item.title"
                              :value="getValue(item)"
                              :details="getDetail(item)"
                              :category="getCategory(item)"
                              :to="item.extraTime && rangeMonth"
                              :tooltip="item.tooltip || ''"
                              :title_class_name="item.title_class_name"
                          ></phieu-luong-item>
                          <template v-else>
                            <phieu-luong-item
                                :class="k % 2 ? 'odd': 'even'"
                                v-for="(log, index) in data.log_human_resources"
                                :key="index + item.title"
                                :hideTitle="index !== 0"
                                :rowSpan="data.log_human_resources.length"
                                :title="item.title"
                                :value="getValue(item, log)"
                                :details="getDetail(item)"
                                :to="log.giaidoan || rangeMonth"
                                :tooltip="item.tooltip || ''"
                                :title_class_name="item.title_class_name"
                            ></phieu-luong-item>
                          </template>
                        </template>
                      </table>
                    </el-row>
                    <el-row v-else>
                      <el-empty
                          :description="'Chưa có thông tin tháng lương của tháng ' + (date ? (( date.getMonth() + 1 ) + '/' + date.getFullYear()) : '')"
                      >
                      </el-empty>
                    </el-row>
                  </div>
                </div>
                <p v-if="data.office === 'backoffice'" class="text-danger color-danger-700">(*) Đề nghị
                  CBNV bảo mật thông tin thu nhập của mình. <br>
                  Mọi thắc mắc vui lòng liên hệ Phòng Nhân sự.
                  <br>Cảm ơn sự đóng góp của bạn vào kết quả chung của Công ty.</p>
                <p v-else class="text-danger color-danger-700">(*) Đề nghị nhân sự bảo mật thông tin thu
                  nhập của mình.
                  <br>
                  Mọi thắc mắc vui lòng liên hệ Trung tâm hỗ trợ theo cú pháp: <br>
                  - Họ và tên + Mã TV <br>
                  - Tên văn phòng <br>
                  - Chi tiết vấn đề cần giải đáp <br>
                  Cảm ơn sự đóng góp của bạn vào kết quả chung của Công ty.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters} from 'vuex';
import {SET_BREADCRUMB} from '@/core/services/store/breadcrumbs.module';
import {PAY_SLIP} from '@/core/services/store/service.module';
import phieuLuongItem from '@/views/pages/human-resource/phieu-luong-item';
import lodash from 'lodash-es';
import deepdash from 'deepdash-es';
import payslipVersion, {payslip_type} from '@/core/config/payslipVersion/index';
import {
  headOfficeType,
  gdvpType,
  gdkvType,
  qlhvType,
  saleInternType,
  saleMemberType,
  saleExpertType,
  saleLeaderType,
  hckvType,
  saleLeaderProvinceType, saleLuongCungType, saleLuongMemType, saleLeaderCenterType,
} from '@/core/config/payslipConfig.js';
import {BOD, STAFF, TEAMLEADER} from "@/core/config/accountTypeOption";
import PhieuLuongDetailItem from "./phieu-luong-detail-item";

const _ = deepdash(lodash);

export default {
  components: {
    PhieuLuongDetailItem,
    phieuLuongItem,
  },
  data() {
    return {
      publicPath: process.env.VUE_APP_BASE_URL,
      data: {},
      loading: true,
      isFocus: false,
      date: null,
      start_of_month: null,
      STAFF: STAFF,
      BOD: BOD,
      TEAMLEADER: TEAMLEADER,
    };
  },
  methods: {
    queryServer(customProperties) {
      this.loading = true;
      let params = {};
      params = _.merge(params, customProperties);
      this.$store.dispatch(PAY_SLIP, params).then((response) => {
        this.data = response.data;
      }).catch((error) => {
        this.data = {}
        this.$message({
          type: 'warning',
          message: `${error.data.message}`,
          showClose: true
        });
      }).finally(() => {
        this.loading = false;
      });
    },
    searchPayslip: function () {
      this.queryServer({category: this.category});
    },
    searchLuong() {
      if (this.date) {
        this.month = this.date.getMonth() + 1;
        this.year = this.date.getFullYear();
      } else {
        let lastMonth = this.$moment().subtract(1, 'months').format('YYYY-MM-DD');
        this.date = new Date(lastMonth);
        this.month = this.date.getMonth() + 1;
        this.year = this.date.getFullYear();
      }
      this.start_of_month = this.year + '-' + (this.month < 10 ? '0' + this.month : this.month) + '-01';
      this.queryServer({
        month: this.month,
        year: this.year,
      });
    },
    getDetail(row) {
      const key = row.key;
      const details = this.data['details'];
      return details;
    },
    getCategory(row) {
      const key = row.key;
      return key;
    },
    //Get value of column based on key and current data
    getValue(row, currentData) {
      //get key of row
      const key = row.key;
      let value = '';
      //if there is currentData then get it. If not get data from this.data
      const data = currentData || this.data;
      //if typeof key is string then value equal of key's data
      if (typeof key === 'string') {
        value = key === 'none' ? '' : data[key]
      }
      //if typeof key is function then value equal of invoke key with data is parameter
      else if (typeof key === 'function') {
        value = key(data);
      }
      //add prefix to value

      switch (row.prefix) {
        case '%':
          value += '%';
          break;
        case '%%':
          if (typeof value == 'string' && value.search('%')) {
          } else {
            value = (value * 100);
            value = value.toFixed(2);
            value += '%';
          }
          break;
        case 'currency':
          value = (value != null && value != '') ? value.toLocaleString('vi-VN', {
            style: 'currency',
            currency: 'VND',
          }) : '0 đ';
          break;
        default:
          break;
      }

      switch (row.convert_value) {
        case 'boolean':
          value = Number(value) === 1 ? 'Có' : 'Không';
          break;
        default:
          break;
      }
      return value;
    },
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      {title: 'Phiếu lương', icon: 'fal fa-money-bill'},
    ]);
    let lastMonth = this.$moment().subtract(1, 'months').format('YYYY-MM-DD');
    this.date = new Date(lastMonth);
    this.month = this.date.getMonth() + 1;
    this.year = this.date.getFullYear();
    this.start_of_month = this.year + '-' + (this.month < 10 ? '0' + this.month : this.month) + '-01';

    this.queryServer({
      month: this.month,
      year: this.year,
    });
  },
  computed: {
    ...mapGetters(['currentUser']),
    payslipType() {
      const logAccountType = this.data?.account_type?.toLowerCase();
      switch (true) {
        case headOfficeType.logAccountType.has(logAccountType):
          return payslip_type.head_office;
        case gdvpType.logAccountType.has(logAccountType):
          return payslip_type.gdvp;
        case gdkvType.logAccountType.has(logAccountType):
          return payslip_type.gdkv;

          // Hanh chinh khu vuc
        case hckvType.logAccountType.has(logAccountType):
          return payslip_type.hckv;

        case qlhvType.logAccountType.has(logAccountType):
          return payslip_type.qlhv;

        case saleInternType.logAccountType.has(logAccountType):
          return payslip_type.sale_intern;

        case saleMemberType.logAccountType.has(logAccountType):
          return payslip_type.sale_member;

        case saleExpertType.logAccountType.has(logAccountType):
          return payslip_type.sale_expert;

        case saleLeaderType.logAccountType.has(logAccountType):
          return payslip_type.sale_leader;

        case saleLeaderCenterType.logAccountType.has(logAccountType):
          return payslip_type.sale_leader_center;

        case saleLeaderProvinceType.logAccountType.has(logAccountType):
          return payslip_type.sale_leader_province;

        case saleLuongCungType.logAccountType.has(logAccountType):
          return payslip_type.sale_luong_cung_v3;

        case saleLuongMemType.logAccountType.has(logAccountType):
          return payslip_type.sale_luong_mem_v3;

        default:
          return '';
      }
    },
    //
    listRow() {
      const type = this.payslipType;
      const start_of_month = this.start_of_month;
      for (const payslip of payslipVersion) {
        let from = payslip.from;
        let to = payslip.to;
        if (from && to) {
          if (start_of_month >= from && start_of_month <= to) {
            return payslip.version[type] || [];
          }
        } else if (from) {
          if (start_of_month >= from) {
            return payslip.version[type] || [];
          }
        } else if (to) {
          if (start_of_month <= to) {
            return payslip.version[type] || [];
          }
        }
      }

      return [];
    },
    // Get date from first day to last day of date's month
    rangeMonth() {
      let y = this.date.getFullYear();
      let m = this.date.getMonth() + 1;
      const firstDay = new Date(y, m, 1);
      const lastDay = new Date(y, m + 1, 0);

      return `Từ ${firstDay.getDate()}/${m}/${y} - ${lastDay.getDate()}/${m}/${y}`;
    },
  },
};
</script>
