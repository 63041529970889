const gdkv = require('./gdkv/gdkv').data;
const gdkv_v1 = require('./gdkv/gdkv-v1').data;
const gdkv_v3 = require('./gdkv/gdkv-v3').data;

const gdvp = require('./gdvp/gdvp').data;
const gdvp_v1 = require('./gdvp/gdvp-v1').data;
const gdvp_v3 = require('./gdvp/gdvp-v3').data;

const head_office = require('./headOffice/head-office').data;
const head_office_v1 = require('./headOffice/head-office-v1').data;
const head_office_v2 = require('./headOffice/head-office-v2').data;
const head_office_v3 = require('./headOffice/head-office-v3').data;

const qlhv = require('./qlhv/qlhv').data;
const qlhv_v2 = require('./qlhv/qlhv-v2').data;
const hckv_v2 = require('./hckv/hckv-v2').data;
const sale_expert = require('./saleExpert/sale-expert').data;
const sale_expert_v1 = require('./saleExpert/sale-expert-v1').data;

const sale_intern = require('./saleIntern/sale-intern').data;

const sale_leader = require('./saleLeader/sale-leader').data;
const sale_leader_v1 = require('./saleLeader/sale-leader-v1').data;
const sale_leader_center_v3 = require('./saleLeader/sale-leader-center-v3').data;
const sale_leader_province_v3 = require('./saleLeader/sale-leader-province-v3').data;
const sale_luong_cung_v3 = require('./sales/sale-luong-cung').data;
const sale_luong_mem_v3 = require('./sales/sale-luong-mem').data;

const sale_member = require('./saleMember/sale-member').data;

const type = {
    gdkv: 'gdkv',
    gdvp: 'gdvp',
    head_office: 'head_office',
    qlhv: 'qlhv',
    hckv: 'hckv',
    sale_expert: 'sale_expert',
    sale_intern: 'sale_intern',
    sale_leader: 'sale_leader',
    sale_leader_province: 'sale_leader_province',
    sale_leader_center: 'sale_leader_center',
    sale_member: 'sale_member',
    sale_luong_cung_v3: 'sale_luong_cung_v3',
    sale_luong_mem_v3: 'sale_luong_mem_v3'
}

const version_old = {};
version_old[type.gdkv] = gdkv;
version_old[type.gdvp] = gdvp;
version_old[type.head_office] = head_office;
version_old[type.qlhv] = qlhv;
version_old[type.sale_expert] = sale_expert;
version_old[type.sale_intern] = sale_intern;
version_old[type.sale_leader] = sale_leader;
version_old[type.sale_member] = sale_member;

const version_v1 = {};
version_v1[type.gdkv] = gdkv_v1;
version_v1[type.gdvp] = gdvp_v1;
version_v1[type.head_office] = head_office_v1;
version_v1[type.qlhv] = qlhv;
version_v1[type.sale_expert] = sale_expert_v1;
version_v1[type.sale_intern] = sale_intern;
version_v1[type.sale_leader] = sale_leader_v1;
version_v1[type.sale_member] = sale_member;

const version_v2 = {};
version_v2[type.gdkv] = gdkv_v1;
version_v2[type.gdvp] = gdvp_v1;
version_v2[type.head_office] = head_office_v2;
version_v2[type.qlhv] = qlhv_v2;
version_v2[type.hckv] = hckv_v2;
version_v2[type.sale_expert] = sale_expert_v1;
version_v2[type.sale_intern] = sale_intern;
version_v2[type.sale_leader] = sale_leader_v1;
version_v2[type.sale_member] = sale_member;

// v3 theo chính sách kinh doanh mới
const version_v3 = {};
version_v3[type.gdkv] = gdkv_v3;
version_v3[type.gdvp] = gdvp_v3;
version_v3[type.head_office] = head_office_v3;
version_v3[type.qlhv] = qlhv_v2;
version_v3[type.hckv] = hckv_v2;
version_v3[type.sale_expert] = sale_expert_v1;
version_v3[type.sale_intern] = sale_intern;
version_v3[type.sale_member] = sale_member;
version_v3[type.sale_leader_center] = sale_leader_center_v3;
version_v3[type.sale_leader_province] = sale_leader_province_v3;
version_v3[type.sale_luong_cung_v3] = sale_luong_cung_v3;
version_v3[type.sale_luong_mem_v3] = sale_luong_mem_v3;

const configPaySlipTime = [
    {
        'from': null,
        'to': '2022-03-31',
        'version': version_old,
    },
    {
        'from': '2022-04-01',
        'to': '2022-07-31',
        'version': version_v1
    },
    {
        'from': '2022-08-01',
        'to': '2022-10-30',
        'version': version_v2
    },
    {
        'from': '2022-11-01',
        'to': null,
        'version': version_v3
    },
]
export const payslip_type = type;
export default configPaySlipTime;