export const data = [
  {
    key: 'muc_luong',
    title: 'Mức lương',
    prefix: 'currency',
  },
  {
    key: 'attendance',
    title: 'Công đi làm thực tế',
  },
  {
    key: 'so_nghi_phep_nam',
    title: 'Số ngày phép',
  },
  {
    key: 'so_ngay_phep_con_lai',
    title: 'Số ngày phép còn lại',
  },
  {
    key: 'so_nghi_phep_khac',
    title: 'Ngày Lễ, Tết, Việc riêng',
  },
  {
    key: 'tong_cong_thang',
    title: 'Tổng công tháng',
  },
  {
    key: 'kpi_deadline',
    title: '% KPI hoàn thành công việc',
    prefix: '%',
  },
  {
    key: 'tong_thu_nhap',
    title: 'Tổng thu nhập',
    prefix: 'currency',
  },
  {
    key: 'tien_phu_cap',
    title: 'Phụ cấp',
    prefix: 'currency',
  },
  {
    key: 'thuong',
    title: 'Thưởng DS/CV',
    prefix: 'currency',
  },
  {
    key: 'phat_cong',
    title: 'Không đủ công',
  },
  {
    key: 'sinh_nhat',
    title: 'Sinh nhật',
    prefix: 'currency',
  },
  {
    key: 'tien_bao_hiem',
    title: 'Trừ đóng BH',
    prefix: 'currency',
  },
  {
    key: 'thu_nhap_thieu_thang_truoc',
    title: 'Thu nhập thiếu tháng trước',
    prefix: 'currency',
  },
  {
    key: 'phat',
    title: 'Phạt',
    prefix: 'currency',
  },
  {
    key: 'tam_ung',
    title: 'Tạm ứng',
    prefix: 'currency',
  },
  {
    key: 'mba',
    title: 'MBA',
  },
  {
    key: 'tru_khac',
    title: 'Trừ khác',
    prefix: 'currency',
  },
  {
    key: 'salary',
    title: 'Lương thực nhận',
    prefix: 'currency',
  },
];