export const data = [
    //Thông tin chung
    {
        key: 'none',
        title: 'Thông tin chung',
        space_before_row: true,
        title_class_name: 'bold text-sm-h5',
    },
    {
        title: "Cơ chế lương ",
        key: "co_che_luong",
        tooltip: ""
    },
    {
        title: "Chỉ tiêu doanh số cá nhân (1)",
        key: "chi_tieu_doanh_so_ca_nhan",
        tooltip: "",
        prefix: 'currency'
    },
    {
        title: "% thưởng không đạt chỉ tiêu (2)",
        key: "phan_tram_thuong_khong_dat_chi_tieu",
        tooltip: "",
        prefix: '%%'
    },
    {
        title: "% thưởng đạt chỉ tiêu (3)",
        key: "phan_tram_thuong_dat_chi_tieu",
        tooltip: "",
        prefix: '%%'
    },
    //3. Kết quả đạt được trong tháng
    {
        key: 'none',
        title: 'Kết quả đạt được trong tháng',
        space_before_row: true,
        title_class_name: 'bold text-sm-h5',
    },
    {
        title: "Doanh số tư vấn (4)",
        key: "doanh_so_tu_van",
        tooltip: "",
        prefix: 'currency'
    },
    {
        title: "Doanh số đội (5)",
        key: "doanh_so_doi",
        tooltip: "",
        prefix: 'currency'
    },
    {
        title: "Doanh số cá nhân (6)",
        key: "doanh_so_ca_nhan",
        tooltip: "",
        prefix: 'currency'
    },
    {
        key: 'thuong_trai_phieu_tich_luy',
        title: 'Thưởng trái phiếu tích lũy',
        prefix: "currency"
    },
    {
        key: 'thoi_diem_trai_phieu_co_hieu_luc',
        title: 'Thời điểm trái phiếu có hiệu lực',
        prefix: "currency"
    },
    //4. Các khoản thu nhập
    {
        key: 'none',
        title: 'Các khoản thu nhập',
        space_before_row: true,
        title_class_name: 'bold text-sm-h5',
    },
    {
        title: "% Thưởng Doanh số (7)",
        key: "phan_tram_thuong_doanh_so",
        tooltip: "",
        prefix: '%%'
    },
    {
        title: "Thưởng doanh số (8) = (6) * (7)",
        key: "thuong_doanh_so",
        tooltip: "",
        prefix: 'currency'
    },
    {
        title: "% Thưởng tư vấn (9)",
        key: "phan_tram_thuong_tu_van",
        tooltip: "Áp dụng từ Sales Executive trở lên",
        prefix: '%%'
    },
    {
        title: "Thưởng tư vấn (10) = (4) * (9)",
        key: "thuong_tu_van",
        tooltip: "",
        prefix: 'currency'
    },
    {
        title: "% Thưởng hỗ trợ đội (11)",
        key: "phan_tram_thuong_ho_tro_doi",
        tooltip: "Áp dụng từ Sales Pro trở lên",
        prefix: '%%'
    },
    {
        title: "Thưởng hỗ trợ đội (12) = (5) * (11)",
        key: "thuong_ho_tro_doi",
        tooltip: "",
        prefix: 'currency'
    },
    {
        title: "Thưởng khác (13)",
        key: "thuong_khac",
        tooltip: "",
        prefix: 'currency'
    },
    {
        title: "Phụ cấp (14)",
        key: "phu_cap",
        tooltip: "",
        prefix: 'currency'
    },
    {
        title: "Thu nhập thiếu tháng trước (15)",
        key: "thu_nhap_thieu_thang_truoc",
        tooltip: "",
        prefix: 'currency'
    },
    {
        title: "Tổng các khoản thu nhập (16) = (8) + (9) + (12) + (13) + (14) + (15)",
        key: "tong_cac_khoan_thu_nhap",
        tooltip: "",
        prefix: 'currency'
    },
    //5. Các khoản trừ
    {
        key: 'none',
        title: 'Các khoản trừ',
        space_before_row: true,
        title_class_name: 'bold text-sm-h5',
    },
    {
        title: "Trích đóng BHXH (17)",
        key: "trich_dong_bhxh",
        tooltip: "",
        prefix: 'currency'
    },
    {
        title: "Phạt (18)",
        key: "phat",
        tooltip: "",
        prefix: 'currency'
    },
    {
        title: "Trừ khác (19)",
        key: "tru_khac",
        tooltip: "",
        prefix: 'currency'
    },
    {
        title: "Tổng các khoản trừ thu nhập (20) = (17) + (18) + (19)",
        key: "tong_cac_khoan_tru_thu_nhap",
        tooltip: "",
        prefix: 'currency'
    },
    {
        title: "Thử việc ",
        key: "thu_viec",
        tooltip: ""
    },
    {
        title: "Ghi chú ",
        key: "ghi_chu",
        tooltip: ""
    },
    {
        title: "Thu nhập thực nhận (21) = (16) - (20)",
        key: "thu_nhap_thuc_nhan",
        tooltip: "Trong thời gian thử việc: (21) = 85% * (16) - (20)",
        space_before_row: true,
        title_class_name: 'bold text-sm-h5',
    prefix: 'currency'
    }
];