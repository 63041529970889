<template>
	<div>
		<el-table :data="details">
			<el-table-column label="Khu vực" width="200">
				<template slot-scope="scope">
					{{ scope.row.khu_vuc }}
				</template>
			</el-table-column>
			<el-table-column label="Bậc" width="200">
				<template slot-scope="scope">
					{{ scope.row.bac }}
				</template>
			</el-table-column>

			<el-table-column label="Chỉ tiêu Doanh số" width="200">
				<template slot-scope="scope">
					{{ formatPrice(scope.row.chi_tieu_doanh_so) }}
				</template>
			</el-table-column>

			<el-table-column label="Doanh số khu vực" width="200">
				<template slot-scope="scope">
					{{ formatPrice(scope.row.doanh_so_khu_vuc) }}
				</template>
			</el-table-column>

			<el-table-column label="KPI Doanh số" width="200">
				<template slot-scope="scope">
					{{ formatPercent(scope.row.kpi_doanh_so) }}
				</template>
			</el-table-column>

			<el-table-column label="Lợi nhuận kinh doanh" width="200">
				<template slot-scope="scope">
					{{ formatPrice(scope.row.loi_nhuan_kinh_doanh) }}
				</template>
			</el-table-column>

			<el-table-column label="50% lợi nhuận chia trong tháng" width="300">
				<template slot-scope="scope">
					{{ formatPrice(scope.row['50_loi_nhuan_chia_trong_thang']) }}
				</template>
			</el-table-column>

			<el-table-column label="% Thưởng KPI" width="200">
				<template slot-scope="scope">
					{{ formatPercent(scope.row.thuong_kpi) }}
				</template>
			</el-table-column>

			<el-table-column label="Thưởng KPI" width="200">
				<template slot-scope="scope">
					{{ formatPrice(scope.row.thuong_kpi) }}
				</template>
			</el-table-column>


			<el-table-column label="% Cổ phần" width="200">
				<template slot-scope="scope">
					{{ formatPercent(scope.row.phan_tram_co_phan) }}

				</template>
			</el-table-column>

			<el-table-column label="Thu nhập cổ tức theo tháng" width="200">
				<template slot-scope="scope">
					{{
						formatPrice(scope.row.thu_nhap_co_tuc_theo_thang)
					}}
				</template>
			</el-table-column>

			<el-table-column label="Ghi chú" width="200">
				<template slot-scope="scope" style="white-space: pre-line">
					{{ scope.row.ghi_chu }}
				</template>
			</el-table-column>
		</el-table>
	</div>
</template>

<script>
import {formatPercent, formatVND} from "../../../../core/filters";

export default {
	name: "GdkvDetail",
	props: {
		details: {
			type: Array,
			default: [],
		}
	},
	methods: {
		formatPrice(item) {
			if (typeof item == 'string' && item.search('đ')) {
				return item;
			} else {
				return formatVND(item);
			}
		},
		formatPercent(item) {
			if (typeof item == 'string' && item.search('%')) {
				return item;
			} else {
				return formatPercent(item);
			}
		},
	},
}
</script>

<style scoped>

</style>