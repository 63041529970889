<template>
	<div>
		<el-button type="text" @click="dialogTableVisible = true">Xem chi tiết</el-button>
		<el-dialog title="Xem chi tiết" :visible.sync="dialogTableVisible">
			<gdkv-detail v-if="firstItemType === DETAIL_GDKV" :details="details"></gdkv-detail>
			<gdvp-detail v-if="firstItemType === DETAIL_GDVP" :details="details"></gdvp-detail>
			<center-detail v-if="firstItemType === DETAIL_TRUNG_TAM" :details="details"></center-detail>
			<province-detail v-if="firstItemType === DETAIL_TINH" :details="details"></province-detail>
		</el-dialog>
	</div>
</template>


<script>
import {DETAIL_GDKV, DETAIL_GDVP, DETAIL_TINH, DETAIL_TRUNG_TAM} from "../../../core/config/payslipConfig";
import GdkvDetail from "./Details/GdkvDetail";
import GdvpDetail from "./Details/GdvpDetail";
import ProvinceDetail from "./Details/ProvinceDetail";
import CenterDetail from "./Details/CenterDetail";

export default {
	name: 'phieu-luong-detail-item',
	components: {CenterDetail, ProvinceDetail, GdvpDetail, GdkvDetail},
	props: {
		details: {
			type: Array,
			default: [],
		}
	},
	data() {
		return {
			dialogTableVisible: false,
			DETAIL_GDKV: DETAIL_GDKV,
			DETAIL_GDVP: DETAIL_GDVP,
			DETAIL_TRUNG_TAM: DETAIL_TRUNG_TAM,
			DETAIL_TINH: DETAIL_TINH,
		}
	},
	computed: {
		firstItemType() {
			if (this.details.length > 0) {
				return this.details[0].type;
			} else {
				return 'gdkv_chi_tiet';
			}
		}
	}
};
</script>
<style>
.pointer {
	cursor: pointer;
}
</style>